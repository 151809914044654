import './App.css';
import RequestForm from './Components/Main/Main';
import People from './Components/People/People';
import Newsletter from './Components/NewsLetter/NewsLetter';

function App() {
  return (
    <div className="App">
      <People></People>
      <RequestForm></RequestForm>
      <Newsletter></Newsletter>
    </div>
  );
}

export default App;
