import React, { useState  } from 'react';
import axios from 'axios';
import '../../index.css';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const RequestInvitationhtmlForm = () => {
    
    const [htmlFormData, sethtmlFormData] = useState({
        name: '',
        email: '',
        state: '',
        phone_number: '',
        recaptcha_response: '',
    });


    
    


    const handleSubmit = async (event) => {
        event.preventDefault();

        
        try {
            const API_URL = 'https://thepeopleshouse.co/api/landing-page/request-invitation/';
            const response = await axios.post(API_URL,htmlFormData);
            console.log('API response:', response.data);
            
            if(response.status === 201){
                sethtmlFormData({
                    name: '',
                    email: '',
                    state: '',
                    phone_number: '',
                    recaptcha_response: '',
                });

                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                toast.success("Invitation requested", {
                    position: 'top-right',
                    autoClose: 3000, 
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                console.error('Failed to submit form:', response.statusText);
            }
        } catch (error) {
            if (error?.response?.data?.message?.phone_number) {
                toast.error(error?.response?.data?.message?.phone_number[0]);
            } else {
                toast.error('Please enter valid form data');
            }
        }
    };

    const handleRecaptchaChange = (value) => {
        sethtmlFormData({
            ...htmlFormData,
            recaptcha_response: value,
        });
    };

    

  return (
    <div className='bg-[#B22234]'>
        <div className="w-full container mx-auto">
            <div className="py-20">
                <h2 className="mb-8 text-left font-medium text-white border-l-[3px] border-white pl-4 text-[40px] h-[60px] flex items-center">Request Invitation</h2>
                <form onSubmit={handleSubmit}>
                    <div className="lg:columns-2 md:columns-1 gap-20">
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-left text-white font-medium text-[14px] mb-2">Name</label>
                            <input 
                                type="text" 
                                id="name" 
                                className="appearance-none border rounded-md w-full py-2 px-4 leading-tight focus:outline-none focus:drop-shadow-md placeholder-[#61272E] h-[44px] bg-[#C14E5D] border-none shadow-sm" 
                                placeholder="Your Name" 
                                onChange={(e) => {
                                    
                                    sethtmlFormData({
                                        ...htmlFormData,
                                        name: e.target.value,
                                    });
                                }}

                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="state" className="block text-left text-white font-medium text-[14px] mb-2">State / Territory</label>
                            <input 
                                type="text" 
                                id="state" 
                                className="appearance-none border rounded-md w-full py-2 px-4 leading-tight focus:outline-none focus:drop-shadow-md placeholder-[#61272E] h-[44px] bg-[#C14E5D] border-none shadow-sm" 
                                placeholder="State / Territory" 
                                onChange={(e) => {
                                    sethtmlFormData({
                                        ...htmlFormData,
                                        state: e.target.value,
                                    });
                                }}

                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-left text-white font-medium text-[14px] mb-2">Email</label>
                            <input 
                                type="email" 
                                id="email" 
                                className="appearance-none border rounded-md w-full py-2 px-4 leading-tight focus:outline-none focus:drop-shadow-md placeholder-[#61272E] h-[44px] bg-[#C14E5D] border-none shadow-sm" 
                                placeholder="Email" 
                                onChange={(e) => {
                                    
                                    sethtmlFormData({
                                        ...htmlFormData,
                                        email: e.target.value,
                                    });
                                }}

                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-left text-white font-medium text-[14px] mb-2">Phone Number</label>
                            <input 
                                type="tel" 
                                id="phone_number" 
                                className="appearance-none border rounded-md w-full py-2 px-4 leading-tight focus:outline-none focus:drop-shadow-md placeholder-[#61272E] h-[44px] bg-[#C14E5D] border-none shadow-sm" 
                                placeholder="Phone Number (123-4567890)" 
                                pattern='^\+\d{1,3}\d{10}$'
                                onChange={(e) => {
                                    sethtmlFormData({
                                        ...htmlFormData,
                                        phone_number: e.target.value,
                                    });
                                }}

                            />
                        </div>
                    </div>

                    {/* Google recaptcha added */}
                    <div className=' flex justify-center my-3'>
                        <ReCAPTCHA
                            sitekey='6Ld4Vq0pAAAAAC9659GZouEN2oViG3wSxoNmrRpw'
                            onChange={handleRecaptchaChange}
                        ></ReCAPTCHA>
                    </div>

                    <div className="text-center">
                        <button type="submit" disabled={
                            !htmlFormData.name ||
                            !htmlFormData.email ||
                            !htmlFormData.state ||
                            !htmlFormData.recaptcha_response
                        }  className="bg-[#002868] disabled:opacity-70 text-white py-2 px-4 text-[14px] rounded-md focus:outline-none focus:shadow-md">
                            Request Invitation
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <ToastContainer />
    </div>
    );
};



export default RequestInvitationhtmlForm;