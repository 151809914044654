import React, { useState, useEffect } from 'react';
import '../../index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'; 

const NewsletterSubscription = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://thepeopleshouse.co/api/landing-page/subscribe-newsletter/', { email });
            
            if (response.status === 201) {
                setEmail(''); 

                toast.success('Subscribed to newsletter', {
                    position: 'top-right',
                    autoClose: 3000, 
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
            } else {
                toast.error('Plese enter valid email', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Email already exists.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    };


    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    const handleChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setIsValidEmail(validateEmail(newEmail));
    }

    

    // useEffect(() => {
    //     const submitButton = document.getElementById('submitBtn');
    //     if (submitButton) {
    //         submitButton.disabled = !isValidEmail;
    //     }
    // }, [isValidEmail]);
    

    return (
        <div className='mt-[127px]'>
            <div className=' bg-[#002868] '>
                <div className='grid grid-cols-12'>
                    <div className='col-span-6 lg:col-span-6 md:col-span-12 sm:col-span-12 xs:col-span-12 newstler sm:bg-[#B22234] -mt-10 flex items-center p-[25px]  justify-start'>
                        <div className='text-white ml-12 xs:ml-0'>
                            <span className='xl:text-[32px] lg:text-[27px] md:text-[25px] sm:text-[30px] xs:text-[20px] text-left block'>
                                Subscribe to our
                            </span>
                            <span className='xl:text-[56px] lg:text-[45px] md:text-[45px] sm:text-[50px] xs:text-[40px] font-bold block'>
                                NEWSLETTER
                            </span>
                        </div>
                    </div>
                    <div className='col-span-6 lg:col-span-6 md:col-span-12 sm:col-span-12 xs:col-span-12'>
                        <div className="form-control mt-[50px] pb-10 rounded-[1px] block mx-auto">
                            <div className='mx-60 xl:mx-60 lg:mx-16 md:mx-32  sm:mx-6 xs:mx-0'>
                                <form onSubmit={handleSubmit} className='flex justify-center items-center md:flex-row sm:flex-col sm:gap-2 xs:flex-col xs:gap-3'>
                                    <div className='flex justify-start items-center custom-width'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6 mx-2 absolute"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                            />
                                        </svg>
                                        <input
                                            type="email"
                                            placeholder="Enter your Email Here"
                                            value={email}
                                            onChange={handleChange}
                                            className="w-full appearance-none border rounded-md py-3 px-10 leading-tight focus:outline-none focus:drop-shadow-md"
                                        />
                                    </div>
                                    <button type="submit" disabled={!isValidEmail} className="bg-[#B22234] disabled:opacity-90 p-3 text-left rounded-md text-white md:inline md:w-auto sm:block sm:w-full sm:text-center xs:block xs:w-full xs:text-center">
                                        Stay&nbsp;Informed
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                progress={undefined}
                deleteToast={(toastId, options) => {
                  // Handle toast deletion here, if needed
                    console.log('Deleting toast:', toastId, options);
                }}
                />
            </div>

            <div className='text-center my-[50px]'>
                <div className='text-center text-[#667085] text-[20px]'>
                    <p>&#169;2023 The Peoples House & All Rights Reserved</p>
                </div>
            </div>
        </div>
    );
};

export default NewsletterSubscription;
