import React, {useEffect, useState} from 'react'
import we_people from '../../Asset/We_The_People.svg'
import axios from 'axios'

const People = () => {
    const [headerData, setHeaderData] = useState(null);

    useEffect(() =>{
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://thepeopleshouse.co/api/landing-page/header-content/',
            headers: { }
        };

        axios.request(config)
        .then((response) => {
            console.log(response.data);
            setHeaderData(response.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });

    },[]);

    const htmlContent= headerData?.content;
    return (
        <div>
            <div>
                <img src={we_people} alt="We The People House" className=" w-full object-cover h-[720px]" />
            </div>
            <div className='container'>
                <div className='my-20 text-center'>
                    <h1 className='text-4xl font-bold mb-4'>{headerData?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </div>
            </div>
        </div>
    )
}

export default People
